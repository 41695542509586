<template>
        <ConfirmationDialogComponent v-if="d_showChangePasswordFailed"
          :p_confirmationMessage="d_changePasswordFailedMessage"
          :p_hasWarning="true"
          p_dialogWidth="45rem"
          p_dialogHeader="Change Password Warning"
          :p_getCancelLabel="m_getCancelLabel"
          :p_cancelCallback="m_cancelChangePasswordFailedCallback"
        />

      <Dialog :header="d_changeOrReset"
              @show="m_onShowCallback"
              :visible="p_showChangePasswordDialog" 
              :style="{width: '46rem'}" 
              :maximizable="false" 
              :closable="false"
              :modal="true"> 

        <form class="new_password" @submit.prevent="onSubmit">
          <label v-if="p_recoveryCode" class="cs_label" for="id_user_name_field">User Name</label>
          <InputText v-if="p_recoveryCode" v-focus 
                     class="password_input" 
                     id="g_cs_user_name_id" 
                     v-model="d_userName" 
                     autocomplete="off"/>

          <label v-if="!p_fromAdminFlag && !p_recoveryCode && !p_forcePasswordChange" class="cs_label" for="id_current_password_field">Current Password</label>
          <Password v-focus
                    v-if="!p_fromAdminFlag && !p_recoveryCode && !p_forcePasswordChange" 
                    id="g_cs_current_password_id"
                    @keyup="m_validatePasswordCallback"
                    v-model="d_currentPasswordValue"
                    autocomplete="off"
                    toggleMask
                    :feedback="false" />
          <label class="cs_label" for="id_new_password_field">{{m_getPasswordLabel()}}</label>
          <Password v-focus
                    id="g_cs_password_id"
                    @keyup="m_validatePasswordCallback"
                    v-model="d_newPasswordValue"
                    autocomplete="off"
                    toggleMask
                    :feedback="false" />
        <label class="cs_label" for="id_user_name_field">Confirm Your Password</label>
        <Password v-focus
                  id="g_cs_confirm_password_id"
                  @keyup="m_validatePasswordCallback"
                  v-model="d_confirmNewPasswordValue"
                  autocomplete="off"
                  toggleMask
                  :feedback="false"/>
        </form>
        <ul style="padding-left: 6rem">
          <li :style="{'color': d_passwordLengthColor}">{{d_passwordLengthMessage}}</li>
          <li :style="{'color': d_passwordLowercaseLetterColor}">{{d_passwordLowercaseLetterMessage}}</li>
          <li :style="{'color': d_passwordUppercaseLetterColor}">{{d_passwordUppercaseLetterMessage}}</li>
          <li :style="{'color': d_passwordNumberColor}">{{d_passwordNumberMessage}}</li>
          <li :style="{'color': d_passwordSymbolColor}">{{d_passwordSymbolMessage}}</li>
          <li :style="{'color': d_passwordMatchColor}">{{d_passwordMatchMessage}}</li>
        </ul>
        <div style="padding-left: 6rem; font-weight: 600">
          {{d_passwordStrength}}
        </div>

        <div :style="m_getButtonParentStyle()">
          <Button v-if="p_closable" id="login_submit_button_id" class="g_cs_button_fix p-button-rounded" @click="m_cancelCallback">Cancel</Button>
          <Button :disabled="!d_enableSubmit" id="login_submit_button_id" class="g_cs_button_fix p-button-rounded" @click="m_newPasswordCallback">Submit</Button>
        </div>

      </Dialog>
</template>

<script>
import Button from 'primevue/button'
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent";
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Dialog from 'primevue/dialog'
import 'primeicons/primeicons.css'
import { passwordStrength } from 'check-password-strength'
import g_showErrorToast  from "../utils/rest_manager.js";

const RED_COLOR = "red";
const GREEN_COLOR = "green";
const PASSWORD_LENGTH_BASE_MESSAGE = "Must be at least 8 characters";
const EMPTY_SPACE = "\xa0";
const SYMBOL_LIST = "!$(),<>@-^_{|}~";

const PASSWORD_UPPERCASE_LETTER_ERROR = "Must contains one uppercase character";
const PASSWORD_LOWERCASE_LETTER_ERROR = "Must contains one lowercase character";
const PASSWORD_NUMBER_ERROR = "Must contains one number";
const PASSWORD_SYMBOL_ERROR = `Must contains one of the symbols: ${SYMBOL_LIST}`;

const PASSWORD_UPPERCASE_LETTER_SUCCESS = "Contains one uppercase character";
const PASSWORD_LOWERCASE_LETTER_SUCCESS = "Contains one lowercase character";
const PASSWORD_NUMBER_SUCCESS = "Contains one number";
const PASSWORD_SYMBOL_SUCCESS = `Contains one symbol: ${SYMBOL_LIST}`;

const PASSWORD_MATCH_ERROR = "Passwords do not match";
const PASSWORD_MATCH_SUCCESS = "Passwords match";

 /**
   * This enables the user to change their password; it validates the password for strength
   */
export default {
  name: "ChangePasswordComponent",
  components: {
    Button,
    Dialog,
    InputText,
    Password,
    ConfirmationDialogComponent,
  },
  data: function () {
    return {
      d_showLoginError: false,
      d_userName: null,
      d_currentPasswordValue: null,
      d_newPasswordValue: null,
      d_newPasswordAcceptable: false,
      d_confirmNewPasswordValue: null,
      d_passwordLengthColor: RED_COLOR,
      d_passwordLowercaseLetterColor: RED_COLOR,
      d_passwordUppercaseLetterColor: RED_COLOR,
      d_passwordNumberColor: RED_COLOR,
      d_passwordSymbolColor: RED_COLOR,
      d_passwordMatchColor: RED_COLOR,
      d_passwordStrength: EMPTY_SPACE,
      d_passwordLengthMessage: PASSWORD_LENGTH_BASE_MESSAGE,  // NEED_I18N
      d_passwordLowercaseLetterMessage: PASSWORD_LOWERCASE_LETTER_ERROR, // NEED_I18N   
      d_passwordUppercaseLetterMessage: PASSWORD_UPPERCASE_LETTER_ERROR, // NEED_I18N  
      d_passwordNumberMessage: PASSWORD_NUMBER_ERROR, // NEED_I18N   
      d_passwordSymbolMessage: PASSWORD_SYMBOL_ERROR, // NEED_I18N   
      d_passwordMatchMessage: PASSWORD_MATCH_ERROR,
      d_enableSubmit: false, 
      d_changeOrReset: null,
      d_showChangePasswordFailed: false,
      d_changePasswordFailedMessage: "Password change failed, please ensure your current password is correct",
    }
  },
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus()
      }
    }
  },
  created() {
    
      if (this.p_recoveryCode) {
        this.d_changeOrReset = "Reset Password"
      }
      else {
        this.d_changeOrReset = "Set Password"
      }
  },
  props: {
    p_showChangePasswordDialog: {type: Boolean, default: false},
    p_submitResetPasswordCallback: {type: Function, default: null},
    p_submitChangedPasswordCallback: {type: Function, default: null},
    p_cancelCallback: {type: Function, default: null},
    p_closable: {type: Boolean, default: false},
    p_recoveryCode: {type: Number, default: null},
    p_forcePasswordChange: {type: Boolean, default: null},
    p_fromAdminFlag: {type: Boolean, default: false},
    p_fromAdminUserName: {type: String, default: null},
    p_newPassword: {type: String, default: null}
  },
  methods: {
    m_getPasswordLabel() {
      if (this.p_forcePasswordChange) {
        return "Password";
      }
      else {
        return "New Password";
      }
    },
    m_getCancelLabel() {
      return "OK";
    },
    m_cancelChangePasswordFailedCallback() {
      this.d_showChangePasswordFailed = false;
    },
    m_onShowCallback() {
      this.m_resetDialog();
    },
    m_resetDialog() {
      this.d_passwordLengthColor = RED_COLOR;
      this.d_passwordLowercaseLetterColor = RED_COLOR;
      this.d_passwordUppercaseLetterColor = RED_COLOR;
      this.d_passwordNumberColor = RED_COLOR;
      this.d_passwordSymbolColor = RED_COLOR;
      this.d_passwordMatchColor = RED_COLOR;
      this.d_passwordStrength = EMPTY_SPACE;
      this.d_currentPasswordValue = null;
      this.d_newPasswordValue = null;
      this.d_confirmNewPasswordValue = null;
      this.m_validatePasswordCallback();
    },
    m_getButtonParentStyle() {
      let style = {
        'padding-top': '1rem',
        'display': 'grid',
        'align-items': 'center',
        'grid-gap': '1rem',
        'justify-content': 'center',
      }

      if (this.p_closable) {
        style = {...style, 'grid-template-columns': 'auto auto'}
      }
      else {
        style = {...style, 'grid-template-columns': 'auto'}
      }

      return style;
    },
    m_cancelCallback() {
      if (this.p_cancelCallback) {
        this.p_cancelCallback();
      }
    },
    async m_newPasswordCallback() {
      if (this.p_recoveryCode) {
        if (this.p_submitResetPasswordCallback) {
          const passwordValue = this.d_newPasswordValue;
          const userName = this.d_userName;
          this.d_enableSubmit = false;
          await this.p_submitResetPasswordCallback(userName, this.p_recoveryCode, passwordValue);
        }
      }
      else {
        if (this.p_submitChangedPasswordCallback) {
          console.log("^^^ change password fromAdminUserNmae", this.p_fromAdminUserName);
          const oldPassword = this.d_currentPasswordValue;
          const newPassword = this.d_newPasswordValue;
          const forcePasswordChange = this.p_forcePasswordChange;
          this.d_enableSubmit = false;
          const success = await this.p_submitChangedPasswordCallback(oldPassword, newPassword, forcePasswordChange);

          if (!success) {
            this.d_showChangePasswordFailed = true;
            this.m_resetDialog();
          }
        }
      }
    },
    m_validatePasswordCallback() {
      let errors = [];
      const password = this.d_newPasswordValue;
      if (!password) {
        return;
      }

      const MIN_LENGTH = 8;
      const difference = MIN_LENGTH - password.length;
      let hasError = false;

      if (difference <= 0) {
        this.d_passwordLengthMessage = PASSWORD_LENGTH_BASE_MESSAGE + ` (current length is ${password.length})`
        this.d_passwordLengthColor = GREEN_COLOR;
        const passwordStrengthValue = passwordStrength(this.d_newPasswordValue).value;
        this.d_passwordStrength = `Password Strength: ${passwordStrengthValue}`;
      }
      else {
        this.d_passwordLengthMessage = PASSWORD_LENGTH_BASE_MESSAGE + ` (${difference} characters remaining)`
        this.d_passwordLengthColor = RED_COLOR;
        this.d_passwordStrength = EMPTY_SPACE;
        hasError = true;
      }

      if (password.search(/[a-z]/) < 0) {
        this.d_passwordLowercaseLetterMessage = PASSWORD_LOWERCASE_LETTER_ERROR;
        this.d_passwordLowercaseLetterColor = RED_COLOR;
        hasError = true;
      }
      else {
        this.d_passwordLowercaseLetterMessage = PASSWORD_LOWERCASE_LETTER_SUCCESS;
        this.d_passwordLowercaseLetterColor = GREEN_COLOR;
      }

      if (password.search(/[A-Z]/) < 0) {
        this.d_passwordUppercaseLetterMessage = PASSWORD_UPPERCASE_LETTER_ERROR;
        this.d_passwordUppercaseLetterColor = RED_COLOR;
        hasError = true;
      }
      else {
        this.d_passwordUppercaseLetterMessage = PASSWORD_UPPERCASE_LETTER_SUCCESS;
        this.d_passwordUppercaseLetterColor = GREEN_COLOR;
      }

      if (password.search(/[0-9]/) < 0) {
        this.d_passwordNumberMessage = PASSWORD_NUMBER_ERROR;
        this.d_passwordNumberColor = RED_COLOR;
        hasError = true;
      }
      else {
        this.d_passwordNumberMessage = PASSWORD_NUMBER_SUCCESS;
        this.d_passwordNumberColor = GREEN_COLOR;
      }

      if (password.search(/[!$(),<>@-^_{|}~]/) < 0) {
        this.d_passwordSymbolMessage = PASSWORD_SYMBOL_ERROR;
        this.d_passwordSymbolColor = RED_COLOR;
        hasError = true;
      }
      else {
        this.d_passwordSymbolMessage = PASSWORD_SYMBOL_SUCCESS;
        this.d_passwordSymbolColor = GREEN_COLOR;
      }

      const newPasswordValue = this.d_newPasswordValue;
      const confirmNewPassword = this.d_confirmNewPasswordValue;


      if (this.d_newPasswordValue && this.d_newPasswordValue.length > 0 && this.d_newPasswordValue === this.d_confirmNewPasswordValue) {
        this.d_passwordMatchColor = GREEN_COLOR;
        this.d_passwordMatchMessage = PASSWORD_MATCH_SUCCESS;
        hasError = false;
      }
      else {
        this.d_passwordMatchColor = RED_COLOR;
        this.d_passwordMatchMessage = PASSWORD_MATCH_ERROR;
        hasError = true;
      }

      // if (!this.p_recoveryCode && !this.d_currentPasswordValue) {
      //   hasError = true;
      // }

      if (hasError) {
        this.d_enableSubmit = false;
      }
      else {
        this.d_enableSubmit = true;
      }
    },
    m_hideLoginErrorDialog() {
      this.d_showLoginError = false;
    },
  }
}
</script>

<style>
@import '../css/cs_globals.css';
</style>
<style scoped>  /* "scoped" attribute limit the CSS to this component only */

* {
  box-sizing: border-box;
}

.new_password {
  padding: 1em;
  border: 1px solid #c1c1c1;
  margin-top: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 11rem 24rem;
  grid-gap: 1rem;
}

form .password-input {
  margin-bottom: 1rem;
  background: #fff;
  border: 1px solid #9c9c9c;
}

.cs_one_button_parent {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  grid-gap: 1rem;
  justify-content: center;
}

.cs_label {
  width: 12rem;
  text-align: right;
  display: block;
  padding: 0.5em 1.5em 0.5em 0;
}

</style>
